<template>
    <v-card flat>
        <v-card-title>
            <span class="secondary--text font-weight-bold">{{ secretLevels[level] }} environment variables</span>
        </v-card-title>

        <v-card-text>
            <div class="d-flex">
                <v-alert type="info" text dense>
                    <strong>Changes will take effect after the application is restarted.</strong>
                </v-alert>
            </div>
            <v-skeleton-loader v-if="requestRunning === 'get'" class="mx-auto" type="table"></v-skeleton-loader>
            <template v-else>
                <div class="d-flex align-center">
                    <v-btn @click="openEnvVarDialog()" class="mr-2" color="primary" v-if="canAddEnvVar()">
                        <v-icon left>add</v-icon>
                        <span class="font-weight-bold">add new variable</span>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-text-field
                        label="Search for an environment variable..."
                        autofocus
                        v-model="search"
                        hide-details
                        prepend-inner-icon="mdi-filter"
                        solo
                        flat
                        background-color="grey lighten-4"
                        dense
                        clearable
                        class="mr-2" />
                    <v-btn @click="fetchEnvVars()" icon><v-icon>refresh</v-icon></v-btn>
                </div>
                <v-data-table
                    :items="envvars"
                    item-key="evid"
                    :headers="headers"
                    :search="search"
                    :options="tableOptions"
                    :footer-props="{ 'items-per-page-options': itemsPerPageOptions }">
                    <template v-slot:top="{ pagination, options, updateOptions }">
                        <v-data-footer
                            :pagination="pagination"
                            :options="options"
                            @update:options="updateOptions"
                            :itemsPerPageOptions="itemsPerPageOptions"
                            items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                            class="no-border" />
                    </template>
                    <template v-slot:no-data>
                        <span>No environment variables have been added, yet. Click add new variable to create a new one.</span>
                    </template>
                    <template v-slot:[`item.name`]="{ item }">
                        <code class="text-body-1">{{ item.name }}</code>
                        <v-tooltip bottom v-if="item.overrides_secret_type">
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon color="primary" small v-bind="attrs" v-on="on" class="ml-3">mdi-information-outline</v-icon>
                            </template>
                            <span>Overrides {{ secretLevels[item.overrides_secret_type].toLocaleLowerCase() }} secret</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="item.env_var_type > level">
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon small v-bind="attrs" v-on="on" class="ml-3">mdi-information-outline</v-icon>
                            </template>
                            <span>Defined for the {{ secretLevels[item.env_var_type].toLowerCase() }}</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:[`item.value`]="{ item }">
                        <code class="text-body-1">{{ item.value }}</code>
                    </template>
                    <template v-slot:[`item.creator_name`]="{ item }">
                        {{ item.last_editor_name || item.creator_name }}
                    </template>
                    <template v-slot:[`item.creation_timestamp`]="{ item }">
                        {{ (item.last_modified_timestamp || item.creation_timestamp) | dateTimeToHuman }}
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <div class="d-flex flex-row justify-end">
                            <copy-to-clipboard
                                :textToCopy="item.name"
                                buttonClass="caption font-weight-bold"
                                tooltip="Copy name to clipboard"
                                :iconButton="true" />
                            <copy-to-clipboard
                                :textToCopy="item.value"
                                buttonClass="caption font-weight-bold"
                                tooltip="Copy value to clipboard"
                                :iconButton="true"
                                icon="mdi-text-box-multiple-outline" />
                            <v-tooltip bottom v-if="canModifyEnvVar(item)">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        icon
                                        small
                                        color="secondary"
                                        @click="openEnvVarDialog(item)"
                                        :loading="selectedEnvVar?.srid === item.srid && requestRunning === 'patch'"
                                        v-bind="attrs"
                                        v-on="on">
                                        <v-icon small>edit</v-icon>
                                    </v-btn>
                                </template>
                                <span>Edit environment varialbe</span>
                            </v-tooltip>
                            <v-tooltip bottom v-if="canModifyEnvVar(item)">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        icon
                                        small
                                        color="secondary"
                                        @click="openDeleteDialog(item)"
                                        :loading="selectedEnvVar?.srid === item.srid && requestRunning === 'delete'"
                                        v-bind="attrs"
                                        v-on="on">
                                        <v-icon small>delete</v-icon>
                                    </v-btn>
                                </template>
                                <span>Delete environment variable</span>
                            </v-tooltip>
                        </div>
                    </template>
                </v-data-table>
            </template>
        </v-card-text>

        <v-dialog v-model="envVarDialog" max-width="600px" lazy-validation persistent>
            <v-card>
                <v-card-title>
                    <span v-if="selectedEnvVar">Edit {{ selectedEnvVar.name }}</span>
                    <span v-else>Add an environment variable</span>
                </v-card-title>
                <v-card-subtitle class="caption">
                    This environment variable will be available in all applications of this {{ secretLevels[level].toLowerCase() }}.
                </v-card-subtitle>
                <v-card-text class="mb-0">
                    <v-form v-model="envVarFormIsValid" @submit.prevent="selectedEnvVar ? sendUpdateRequest() : sendPostRequest()">
                        <v-text-field
                            v-model="newEnvVarName"
                            outlined
                            hint="This field is required"
                            :rules="[rules.required, rules.validFirstChar, rules.validChars]"
                            dense
                            v-if="!selectedEnvVar"
                            :autofocus="!selectedEnvVar">
                            <template v-slot:label>Type a name for your variable</template>
                        </v-text-field>
                        <v-textarea
                            v-model="newEnvVarValue"
                            outlined
                            :rules="[rules.required]"
                            dense
                            hint="This field is required"
                            :autofocus="!!selectedEnvVar">
                            <template v-slot:label>Type a value for your variable</template>
                        </v-textarea>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="closeEnvVarDialog">Cancel</v-btn>
                    <v-btn
                        text
                        color="primary"
                        @click="sendUpdateRequest()"
                        :disabled="!envVarFormIsValid"
                        v-if="selectedEnvVar"
                        :loading="requestRunning === 'patch'">
                        Edit
                    </v-btn>
                    <v-btn v-else text color="primary" @click="sendPostRequest()" :disabled="!envVarFormIsValid" :loading="requestRunning === 'post'">
                        Add
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import { enumsData } from '@/mixins/enums'
import eventBus from '@/components/EventBus'
import { mapGetters, mapState } from 'vuex'

const CopyToClipboard = () => import('@/components/CopyToClipboard.vue')

export default {
    mixins: [enumsData],
    components: { CopyToClipboard },
    props: {
        level: Number
    },
    data() {
        return {
            fetchingEnvVars: false,
            envvars: [],
            search: '',
            selectedEnvVar: null,
            requestRunning: false,
            includeDeleted: false,
            envVarDialog: false,
            envVarFormIsValid: false,
            newEnvVarName: '',
            newEnvVarValue: '',
            rules: {
                required: value => !!value || 'This field is required',
                validChars: p => /^[a-zA-Z0-9_-]*$/g.test(p) || 'Please use only letters, numbers, underscores and dashes.',
                validFirstChar: p => /^[a-zA-Z_]/.test(p) || 'Name can only start with a letter or an underscore.'
            },
            itemsPerPageOptions: [25, 50, 100, -1],
            tableOptions: {
                page: 0,
                itemsPerPage: 25,
                pageStart: 1,
                pageStop: 25,
                itemsLength: 25
            }
        }
    },
    computed: {
        ...mapGetters('spaceStore', ['isSpaceAdmin', 'isSpaceArchived']),
        ...mapGetters('orgStore', ['isOrgManager']),
        ...mapState('userStore', ['isAccountManager']),
        headers() {
            return [
                { text: 'Name', align: 'left', value: 'name' },
                { text: 'Value', align: 'left', value: 'value' },
                { text: 'Last change at', align: 'right', value: 'creation_timestamp' },
                { text: 'Actions', align: 'right', value: 'actions' }
            ]
        },
        paramForLevel() {
            const paramsForLevels = {
                0: `personal`,
                2: `spaces`,
                3: `orgs`
            }
            return paramsForLevels[this.level]
        },
        actions() {
            return {
                delete() {
                    return {
                        endpoint: srid => `/env_vars/personal/${srid}`,
                        success: `Variable successfully deleted.`,
                        error: `There was an error deleting the variable.`
                    }
                },
                patch() {
                    return {
                        endpoint: srid => `/env_vars/personal/${srid}`,
                        success: `Variable successfully updated.`,
                        error: `There was an error updating the variable.`
                    }
                },
                post(level) {
                    return {
                        endpoint: sid => (level === 'personal' ? `/env_vars/personal` : `/${level}/${sid}/env_vars`),
                        success: `Successfully added environment variable.`,
                        error: `There was an error saving the variable.`
                    }
                },
                get(level, includeDeleted) {
                    return {
                        endpoint: sid => (level === 'personal' ? `/env_vars/personal` : `/${level}/${sid}/env_vars?include_deleted=${includeDeleted}`),
                        error: `There was an error fetching environment variables.`
                    }
                }
            }
        }
    },
    methods: {
        openDeleteDialog(envvar) {
            this.selectedEnvVar = envvar
            this.$store.dispatch('showGlobalDialog', {
                dialogText: `Are you sure you would like to delete this variable: <em>${this.selectedEnvVar.name}</em>?`,
                dialogAction: ['delete', 'cancel'],
                dialogTitle: 'Confirm deletion'
            })
        },
        openEnvVarDialog(envvar = null) {
            this.selectedEnvVar = envvar
            this.envVarDialog = true
        },
        closeEnvVarDialog() {
            this.selectedEnvVar = null
            this.envVarDialog = false
        },
        async sendPostRequest() {
            if (!this.envVarFormIsValid) return

            const id = this.level === 2 ? this.$route.params.sid : this.$route.params.oid
            await this.sendRequest('post', id, {
                name: this.newEnvVarName,
                value: this.newEnvVarValue
            })
            this.envVarDialog = false
            this.newEnvVarName = ''
            this.newEnvVarValue = ''
            this.fetchEnvVars()
        },
        async sendUpdateRequest() {
            await this.sendRequest('patch', this.selectedEnvVar.evid, { value: this.newEnvVarValue })
            this.selectedEnvVar = null
            this.envVarDialog = false
            this.newEnvVarValue = ''
            this.fetchEnvVars()
        },
        async sendDeleteRequest() {
            await this.sendRequest('delete', this.selectedEnvVar.evid)
            this.selectedEnvVar = null
            this.envVarDialog = false
            this.fetchEnvVars()
        },
        async sendRequest(method, id, body = {}) {
            this.requestRunning = method
            const axiosRequest = this.$axios[method]
            const level = this.paramForLevel
            const endpoint = this.actions[method](level, this.includeDeleted).endpoint(id)
            let response
            try {
                response = await axiosRequest(endpoint, body)
                'success' in this.actions[method] &&
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: this.actions[method].success,
                        snackBarTimeout: 5000,
                        snackBarIcon: 'check_circle'
                    })
            } catch (error) {
                console.log(this.actions[method])
                this.$store.dispatch('showSnackBar', {
                    snackBarText: error.response?.data?.debug || this.actions[method]().error,
                    snackBarTimeout: 5000,
                    snackBarIcon: 'error'
                })
            }
            this.selectedEnvVar = null
            this.requestRunning = false
            return response
        },
        fetchEnvVars() {
            this.envvars = []
            const id = this.level === 2 ? this.$route.params.sid : this.$route.params.oid
            this.sendRequest('get', id).then(({ data }) => {
                this.envvars = data
            })
        },
        isDeleted: item => item.delete_timestamp && 'text--disabled',
        canModifyEnvVar(envvar) {
            if (envvar.delete_timestamp || envvar.env_var_type !== this.level) return false
            return this.canAddEnvVar()
        },
        canAddEnvVar() {
            if (this.level === 2) return this.isSpaceAdmin && !this.isSpaceArchived
            if (this.level === 3) return this.isOrgManager
            return this.level === 0
        }
    },
    created() {
        this.fetchEnvVars()
        eventBus.$on('global-dialog-accept', async () => {
            this.sendDeleteRequest()
        })
    },
    watch: {
        includeDeleted() {
            this.fetchEnvVars()
        }
    }
}
</script>
